@keyframes animate-forward {
  0% {
    opacity: 1;
    scale: 1;
  }
  50% {
    opacity: 1;
    scale: 1.02;
  }
  100% {
    opacity: 0;
    scale: 0.98;
  }
}
@keyframes animate-back {
  0% {
    opacity: 1;
    scale: 1;
  }
  50% {
    opacity: 1;
    scale: 1.02;
  }
  100% {
    opacity: 0;
    scale: 0.98;
  }
}
#survey-page .survey-loading {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 22px;
}
#survey-page .content-page {
  width: 100%;
  padding: 80px 0 100px 0;
  overflow-y: auto;
}
#survey-page .content-page .survey-content {
  width: 100%;
  margin: auto;
  padding: 10px;
  min-height: 300px;
  max-width: 600px;
}
#survey-page .content-page .survey-content > .animate-box.animate-forward {
  animation: animate-forward 0.5s;
  opacity: 0;
}
#survey-page .content-page .survey-content > .animate-box.animate-back {
  animation: animate-back 0.5s;
  opacity: 0;
}
#survey-page .content-page .survey-content .question-item {
  margin: 50px 0;
  width: 100%;
  flex: 1;
}
#survey-page .content-page .survey-content .question-item .box-content {
  margin-top: 30px;
}
#survey-page .do-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}
#survey-page .do-not-found .content {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#survey-page .do-not-found .content .logo img {
  width: 150px;
  margin-bottom: 50px;
}
#survey-page .do-not-found .content .message {
  width: 100%;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
}
#survey-page .do-not-found .content .footer {
  margin-top: 50px;
}
#survey-page header {
  width: 100%;
  height: 80px;
  border-top: 8px solid #9a2376;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  color: #222;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0;
  z-index: 100;
}
#survey-page header .content-header {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 0px;
  width: 320px;
  margin: 0 auto;
}
#survey-page header .content-header .image {
  height: 70px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
#survey-page header .content-header .image img {
  height: 50px;
}
#survey-page header .content-header .business {
  height: 60px;
  line-height: 60px;
  padding-left: 20px;
  font-weight: bold;
}
#survey-page footer {
  background-color: #f5f5f5;
  position: fixed;
  bottom: 0;
  top: initial;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0;
  padding: 5px 0;
  box-shadow: 0 5px -5px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 -5px 5px 0 rgba(0, 0, 0, 0.2);
  z-index: 100;
  overflow: hidden;
  text-align: center;
  display: block;
}
#survey-page footer .content-footer {
  width: 100%;
  height: 100%;
}
#survey-page footer .content-footer .box-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
}
#survey-page footer .content-footer .box-poweredby {
  width: 100%;
  font-size: 10px;
  padding: 5px 0 0 0;
}
#survey-page footer .content-footer .box-progress .static-bar {
  width: 300px;
  height: 20px;
  border-width: 1px;
  border-style: solid;
  margin: 5px auto;
  border-radius: 10px;
}
#survey-page footer .content-footer .box-progress .static-bar .bar {
  float: left;
  min-width: 25px;
  max-width: 296px;
  height: 16px;
  margin: 1px;
  border-radius: 8px;
  color: white;
  line-height: 16px;
  font-size: 12px;
}
#survey-page footer .content-footer .box-progress .steps-bar {
  width: 150px;
  height: 25px;
  border-width: 1px;
  border-style: solid;
  margin: 5px auto;
  border-radius: 15px;
}
#survey-page footer .content-footer .box-progress .steps-bar .bar {
  width: 50%;
  height: 21px;
  margin: 1px auto;
  border-radius: 12px;
  color: white;
  line-height: 21px;
  font-size: 12px;
}
