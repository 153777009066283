button.button-survey {
  border: none;
  display: inline-block;
  background-color: #9a2376;
  color: #fff;
  border-radius: 5px;
  margin: 10px;
  width: 150px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}
button.button-survey:hover {
  background-color: rgba(154, 35, 118, 0.8);
}
button.button-survey:active {
  background-color: #9a2376;
}
