#auto-complete-component.auto-complete {
  width: 100%;
}
#auto-complete-component.auto-complete .auto-complete-title {
  margin: 20px 0;
  position: relative;
}
#auto-complete-component.auto-complete .auto-complete-title > span.require {
  position: absolute;
  float: left;
  left: -5px;
  top: -5px;
  color: red;
}
#auto-complete-component.auto-complete .auto-complete-content {
  margin: 10px 0;
  width: 100%;
}
#auto-complete-component.auto-complete .auto-complete-content input {
  width: 100%;
  display: block;
  margin: 10px 0;
  border-radius: 5px;
  padding: 10px;
  outline: none;
  border: solid 1px #ccc;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #f5f5f5;
}
#auto-complete-component.auto-complete .auto-complete-content input:focus {
  border: solid 1px #aaa;
  box-shadow: 0 0 0 0.2rem #eee;
}
#auto-complete-component.auto-complete .auto-complete-content .tray-box {
  width: 100%;
  max-width: 300px;
  height: 200px;
  border: solid 1px #ccc;
  border-radius: 5px;
  overflow-y: auto;
  float: left;
  position: absolute;
  z-index: 1000;
  background-color: white;
}
#auto-complete-component.auto-complete .auto-complete-content .tray-box::-webkit-scrollbar {
  width: 10px;
  background-color: #ddd;
  border-radius: 5px;
}
#auto-complete-component.auto-complete .auto-complete-content .tray-box::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 5px;
}
#auto-complete-component.auto-complete .auto-complete-content .tray-box .tray-item {
  padding: 5px 10px;
  cursor: pointer;
}
#auto-complete-component.auto-complete .auto-complete-content .tray-box .tray-item:hover {
  background-color: #eee;
}
#auto-complete-component.auto-complete .auto-complete-content .close-tray-box {
  width: 30px;
  height: 30px;
  border: solid 1px #ccc;
  border-radius: 5px;
  overflow-y: auto;
  float: right;
  right: 10px;
  top: -46px;
  position: relative;
  z-index: 10;
  background-color: white;
  border-radius: 15px;
  font-size: 20px;
  overflow: hidden;
  text-align: center;
  line-height: 28px;
  font-weight: bold;
  color: #aaa;
  cursor: pointer;
}
#auto-complete-component.auto-complete .auto-complete-error {
  margin: 20px 0;
  color: red;
  font-weight: bold;
}
