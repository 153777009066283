.btn-inovyo {
  background-color: var(--color-inovyo);
  padding: 8px 12px;
  color: white;
  outline: none;
  text-decoration: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
}
.btn-inovyo:hover {
  background-color: var(--color-inovyo-light);
}
.btn-inovyo:active {
  background-color: var(--color-inovyo-dark);
}
.btn-inovyo.block {
  width: 100%;
}
.btn-inovyo.large {
  padding: 12px 18px;
}

.btn-inovyo-yellow {
  background-color: var(--color-inovyo-yellow);
  padding: 8px 12px;
  color: white;
  outline: none;
  text-decoration: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
}
.btn-inovyo-yellow:hover {
  background-color: var(--color-inovyo-yellow-light);
}
.btn-inovyo-yellow:active {
  background-color: var(--color-inovyo-yellow-dark);
}
.btn-inovyo-yellow.block {
  width: 100%;
}
.btn-inovyo-yellow.large {
  padding: 12px 18px;
}
