#radiobox-component.radio, #radiobox-component.radio-check, #radiobox-component.check, #radiobox-component.radio-pill, #radiobox-component.pill, #radiobox-component.radio-pill-check, #radiobox-component.pill-check, #radiobox-component.radio-full, #radiobox-component.full {
  width: 100%;
}
#radiobox-component.radio .radiobox-title, #radiobox-component.radio-check .radiobox-title, #radiobox-component.check .radiobox-title, #radiobox-component.radio-pill .radiobox-title, #radiobox-component.pill .radiobox-title, #radiobox-component.radio-pill-check .radiobox-title, #radiobox-component.pill-check .radiobox-title, #radiobox-component.radio-full .radiobox-title, #radiobox-component.full .radiobox-title {
  margin: 20px 0;
  position: relative;
}
#radiobox-component.radio .radiobox-title > span.require, #radiobox-component.radio-check .radiobox-title > span.require, #radiobox-component.check .radiobox-title > span.require, #radiobox-component.radio-pill .radiobox-title > span.require, #radiobox-component.pill .radiobox-title > span.require, #radiobox-component.radio-pill-check .radiobox-title > span.require, #radiobox-component.pill-check .radiobox-title > span.require, #radiobox-component.radio-full .radiobox-title > span.require, #radiobox-component.full .radiobox-title > span.require {
  position: absolute;
  float: left;
  left: -5px;
  top: -5px;
  color: red;
}
#radiobox-component.radio .radiobox-error, #radiobox-component.radio-check .radiobox-error, #radiobox-component.check .radiobox-error, #radiobox-component.radio-pill .radiobox-error, #radiobox-component.pill .radiobox-error, #radiobox-component.radio-pill-check .radiobox-error, #radiobox-component.pill-check .radiobox-error, #radiobox-component.radio-full .radiobox-error, #radiobox-component.full .radiobox-error {
  margin: 20px 0;
  color: red;
  font-weight: bold;
}
#radiobox-component.radio input.radiobox-item,
#radiobox-component.radio input.radiobox-item + label, #radiobox-component.radio-check input.radiobox-item,
#radiobox-component.radio-check input.radiobox-item + label, #radiobox-component.check input.radiobox-item,
#radiobox-component.check input.radiobox-item + label, #radiobox-component.radio-pill input.radiobox-item,
#radiobox-component.radio-pill input.radiobox-item + label, #radiobox-component.pill input.radiobox-item,
#radiobox-component.pill input.radiobox-item + label, #radiobox-component.radio-pill-check input.radiobox-item,
#radiobox-component.radio-pill-check input.radiobox-item + label, #radiobox-component.pill-check input.radiobox-item,
#radiobox-component.pill-check input.radiobox-item + label, #radiobox-component.radio-full input.radiobox-item,
#radiobox-component.radio-full input.radiobox-item + label, #radiobox-component.full input.radiobox-item,
#radiobox-component.full input.radiobox-item + label {
  display: block;
  margin-bottom: 15px;
}
#radiobox-component.radio .radiobox-option, #radiobox-component.radio-check .radiobox-option, #radiobox-component.check .radiobox-option, #radiobox-component.radio-pill .radiobox-option, #radiobox-component.pill .radiobox-option, #radiobox-component.radio-pill-check .radiobox-option, #radiobox-component.pill-check .radiobox-option, #radiobox-component.radio-full .radiobox-option, #radiobox-component.full .radiobox-option {
  display: block;
  cursor: pointer;
  margin-bottom: 15px;
  transition: all 0.1s ease-in-out;
}
#radiobox-component.radio .radiobox-option .box-properties, #radiobox-component.radio-check .radiobox-option .box-properties, #radiobox-component.check .radiobox-option .box-properties, #radiobox-component.radio-pill .radiobox-option .box-properties, #radiobox-component.pill .radiobox-option .box-properties, #radiobox-component.radio-pill-check .radiobox-option .box-properties, #radiobox-component.pill-check .radiobox-option .box-properties, #radiobox-component.radio-full .radiobox-option .box-properties, #radiobox-component.full .radiobox-option .box-properties {
  margin: 10px 0;
  width: 100%;
  padding-left: 30px;
}
#radiobox-component.radio .radiobox-option .box-properties input, #radiobox-component.radio-check .radiobox-option .box-properties input, #radiobox-component.check .radiobox-option .box-properties input, #radiobox-component.radio-pill .radiobox-option .box-properties input, #radiobox-component.pill .radiobox-option .box-properties input, #radiobox-component.radio-pill-check .radiobox-option .box-properties input, #radiobox-component.pill-check .radiobox-option .box-properties input, #radiobox-component.radio-full .radiobox-option .box-properties input, #radiobox-component.full .radiobox-option .box-properties input {
  width: 100%;
  display: block;
  margin: 10px 0;
  border-radius: 5px;
  padding: 10px;
  outline: none;
  border: solid 1px #ccc;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #f5f5f5;
}
#radiobox-component.radio .radiobox-option .box-properties input:focus, #radiobox-component.radio-check .radiobox-option .box-properties input:focus, #radiobox-component.check .radiobox-option .box-properties input:focus, #radiobox-component.radio-pill .radiobox-option .box-properties input:focus, #radiobox-component.pill .radiobox-option .box-properties input:focus, #radiobox-component.radio-pill-check .radiobox-option .box-properties input:focus, #radiobox-component.pill-check .radiobox-option .box-properties input:focus, #radiobox-component.radio-full .radiobox-option .box-properties input:focus, #radiobox-component.full .radiobox-option .box-properties input:focus {
  border: solid 1px #aaa;
  box-shadow: 0 0 0 0.2rem #eee;
}
#radiobox-component.radio .radiobox-option .box-properties > .other-error, #radiobox-component.radio-check .radiobox-option .box-properties > .other-error, #radiobox-component.check .radiobox-option .box-properties > .other-error, #radiobox-component.radio-pill .radiobox-option .box-properties > .other-error, #radiobox-component.pill .radiobox-option .box-properties > .other-error, #radiobox-component.radio-pill-check .radiobox-option .box-properties > .other-error, #radiobox-component.pill-check .radiobox-option .box-properties > .other-error, #radiobox-component.radio-full .radiobox-option .box-properties > .other-error, #radiobox-component.full .radiobox-option .box-properties > .other-error {
  color: red;
}
#radiobox-component.radio .radiobox-option input.checkbox-item, #radiobox-component.radio-check .radiobox-option input.checkbox-item, #radiobox-component.check .radiobox-option input.checkbox-item, #radiobox-component.radio-pill .radiobox-option input.checkbox-item, #radiobox-component.pill .radiobox-option input.checkbox-item, #radiobox-component.radio-pill-check .radiobox-option input.checkbox-item, #radiobox-component.pill-check .radiobox-option input.checkbox-item, #radiobox-component.radio-full .radiobox-option input.checkbox-item, #radiobox-component.full .radiobox-option input.checkbox-item {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
#radiobox-component.radio .radiobox-option input.checkbox-item:checked + label::after, #radiobox-component.radio-check .radiobox-option input.checkbox-item:checked + label::after, #radiobox-component.check .radiobox-option input.checkbox-item:checked + label::after, #radiobox-component.radio-pill .radiobox-option input.checkbox-item:checked + label::after, #radiobox-component.pill .radiobox-option input.checkbox-item:checked + label::after, #radiobox-component.radio-pill-check .radiobox-option input.checkbox-item:checked + label::after, #radiobox-component.pill-check .radiobox-option input.checkbox-item:checked + label::after, #radiobox-component.radio-full .radiobox-option input.checkbox-item:checked + label::after, #radiobox-component.full .radiobox-option input.checkbox-item:checked + label::after {
  opacity: 1;
}
#radiobox-component.radio .radiobox-option + .box-properties, #radiobox-component.radio-check .radiobox-option + .box-properties, #radiobox-component.check .radiobox-option + .box-properties, #radiobox-component.radio-pill .radiobox-option + .box-properties, #radiobox-component.pill .radiobox-option + .box-properties, #radiobox-component.radio-pill-check .radiobox-option + .box-properties, #radiobox-component.pill-check .radiobox-option + .box-properties, #radiobox-component.radio-full .radiobox-option + .box-properties, #radiobox-component.full .radiobox-option + .box-properties {
  margin: 10px 0;
  width: 100%;
  padding-left: 30px;
}
#radiobox-component.radio .radiobox-option + .box-properties input, #radiobox-component.radio-check .radiobox-option + .box-properties input, #radiobox-component.check .radiobox-option + .box-properties input, #radiobox-component.radio-pill .radiobox-option + .box-properties input, #radiobox-component.pill .radiobox-option + .box-properties input, #radiobox-component.radio-pill-check .radiobox-option + .box-properties input, #radiobox-component.pill-check .radiobox-option + .box-properties input, #radiobox-component.radio-full .radiobox-option + .box-properties input, #radiobox-component.full .radiobox-option + .box-properties input {
  width: 100%;
  display: block;
  margin: 10px 0;
  border-radius: 5px;
  padding: 10px;
  outline: none;
  border: solid 1px #ccc;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #f5f5f5;
}
#radiobox-component.radio .radiobox-option + .box-properties input:focus, #radiobox-component.radio-check .radiobox-option + .box-properties input:focus, #radiobox-component.check .radiobox-option + .box-properties input:focus, #radiobox-component.radio-pill .radiobox-option + .box-properties input:focus, #radiobox-component.pill .radiobox-option + .box-properties input:focus, #radiobox-component.radio-pill-check .radiobox-option + .box-properties input:focus, #radiobox-component.pill-check .radiobox-option + .box-properties input:focus, #radiobox-component.radio-full .radiobox-option + .box-properties input:focus, #radiobox-component.full .radiobox-option + .box-properties input:focus {
  border: solid 1px #aaa;
  box-shadow: 0 0 0 0.2rem #eee;
}
#radiobox-component.radio input.radiobox-item[type=radio] {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
#radiobox-component.radio input.radiobox-item[type=radio] + label {
  position: relative;
  cursor: pointer;
  padding-left: 35px;
}
#radiobox-component.radio input.radiobox-item[type=radio] + label::before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  top: -4px;
  left: 0;
  bottom: 0;
  border: solid 2px black;
  vertical-align: bottom;
  border-radius: 50%;
}
#radiobox-component.radio input.radiobox-item[type=radio]:checked + label::after {
  content: "";
  position: absolute;
  left: 4px;
  bottom: 4px;
  width: 18px;
  height: 18px;
  top: 0px;
  background-color: black;
  border-radius: 50%;
}
#radiobox-component.radio-check input.radiobox-item[type=radio], #radiobox-component.check input.radiobox-item[type=radio] {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
#radiobox-component.radio-check input.radiobox-item[type=radio] + label, #radiobox-component.check input.radiobox-item[type=radio] + label {
  position: relative;
  cursor: pointer;
  padding-left: 35px;
}
#radiobox-component.radio-check input.radiobox-item[type=radio] + label::before, #radiobox-component.check input.radiobox-item[type=radio] + label::before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  top: -4px;
  left: 0;
  bottom: 0;
  border: solid 2px black;
  vertical-align: bottom;
  border-radius: 50%;
}
#radiobox-component.radio-check input.radiobox-item[type=radio]:checked + label::after, #radiobox-component.check input.radiobox-item[type=radio]:checked + label::after {
  content: "";
  position: absolute;
  left: 8px;
  bottom: 6px;
  width: 8px;
  height: 16px;
  top: 0px;
  border-right: solid 3px black;
  border-bottom: solid 3px black;
  transform: rotate(45deg);
}
#radiobox-component.radio-pill input.radiobox-item[type=radio], #radiobox-component.pill input.radiobox-item[type=radio] {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
#radiobox-component.radio-pill input.radiobox-item[type=radio] + label, #radiobox-component.pill input.radiobox-item[type=radio] + label {
  position: relative;
  cursor: pointer;
  padding-left: 55px;
}
#radiobox-component.radio-pill input.radiobox-item[type=radio] + label::before, #radiobox-component.pill input.radiobox-item[type=radio] + label::before {
  content: "";
  position: absolute;
  width: 46px;
  height: 26px;
  top: -4px;
  left: 0;
  bottom: 0;
  border: solid 2px black;
  vertical-align: bottom;
  border-radius: 13px;
}
#radiobox-component.radio-pill input.radiobox-item[type=radio]:checked + label::after, #radiobox-component.pill input.radiobox-item[type=radio]:checked + label::after {
  content: "";
  position: absolute;
  left: 6px;
  bottom: 4px;
  width: 34px;
  height: 18px;
  top: 0px;
  background-color: black;
  border-radius: 10px;
}
#radiobox-component.radio-pill-check input.radiobox-item[type=radio], #radiobox-component.pill-check input.radiobox-item[type=radio] {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
#radiobox-component.radio-pill-check input.radiobox-item[type=radio] + label, #radiobox-component.pill-check input.radiobox-item[type=radio] + label {
  position: relative;
  cursor: pointer;
  padding-left: 55px;
}
#radiobox-component.radio-pill-check input.radiobox-item[type=radio] + label::before, #radiobox-component.pill-check input.radiobox-item[type=radio] + label::before {
  content: "";
  position: absolute;
  width: 46px;
  height: 26px;
  top: -4px;
  left: 0;
  bottom: 0;
  border: solid 2px black;
  vertical-align: bottom;
  border-radius: 13px;
}
#radiobox-component.radio-pill-check input.radiobox-item[type=radio]:checked + label::after, #radiobox-component.pill-check input.radiobox-item[type=radio]:checked + label::after {
  content: "";
  position: absolute;
  left: 18px;
  bottom: 5px;
  width: 10px;
  top: 0px;
  height: 18px;
  border-right: solid 3px black;
  border-bottom: solid 3px black;
  transform: rotate(45deg);
}
#radiobox-component.radio-full .radiobox-option, #radiobox-component.full .radiobox-option {
  background-color: #eee;
  border: solid 1px #ddd;
  height: 40px;
  margin-bottom: 10px;
  text-align: center;
  line-height: 40px;
  border-radius: 20px;
  cursor: pointer;
}
#radiobox-component.radio-full .radiobox-option:hover, #radiobox-component.full .radiobox-option:hover {
  background-color: #e1e1e1;
}
#radiobox-component.radio-full .radiobox-option.selected, #radiobox-component.full .radiobox-option.selected {
  background-color: #7b7b7b;
  border: solid 1px #535353;
  color: #fff;
}
#radiobox-component.shadow .radiobox-option {
  box-shadow: 2px 2px 0 0 #414141;
}
#radiobox-component.shadow .radiobox-option:hover {
  transform: translateY(-1px) translateX(-2px);
  box-shadow: 3px 3px 0 0 #414141;
}
#radiobox-component.shadow .radiobox-option.selected {
  transform: translateY(1px) translateX(1px);
  box-shadow: 0 0 0 0 #414141;
}
