#textarea-component {
  /*cascading-dropdown design*/
}
#textarea-component.textarea {
  width: 100%;
}
#textarea-component.textarea .textarea-question {
  margin: 20px 0;
  position: relative;
}
#textarea-component.textarea .textarea-question > span.require {
  position: absolute;
  float: left;
  left: -5px;
  top: -5px;
  color: red;
}
#textarea-component.textarea .textarea-content {
  margin: 20px 0;
  width: 100%;
}
#textarea-component.textarea .textarea-content textarea {
  width: 100%;
  display: block;
  margin: 10px 0;
  border-radius: 5px;
  padding: 10px;
  outline: none;
  border: solid 1px #ccc;
  resize: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #f5f5f5;
}
#textarea-component.textarea .textarea-content textarea:focus {
  border: solid 1px #aaa;
  box-shadow: 0 0 0 0.2rem #eee;
}
#textarea-component.textarea .textarea-content .count-words {
  width: 100%;
  color: #888;
}
#textarea-component.textarea .textarea-error {
  margin: 20px 0;
  color: red;
  font-weight: bold;
}
