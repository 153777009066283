#image-upload-component.image-upload {
  width: 100%;
}
#image-upload-component.image-upload .image-upload-title {
  margin: 20px 0;
  position: relative;
}
#image-upload-component.image-upload .image-upload-title > span.require {
  position: absolute;
  float: left;
  left: -5px;
  top: -5px;
  color: red;
}
#image-upload-component.image-upload .image-upload-error {
  margin: 20px 0;
  color: red;
  font-weight: bold;
}
#image-upload-component.image-upload .image-upload-mask {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  float: left;
  z-index: 1000;
  font-size: 30px;
}
#image-upload-component.image-upload .image-upload-mask img {
  max-width: 90%;
  max-height: 90%;
}
#image-upload-component.image-upload .image-upload-mask .btn-close-mask {
  position: absolute;
  float: right;
  top: 15px;
  right: 15px;
  z-index: 2;
  background-color: #aaa;
  height: 25px;
  width: 25px;
  border-radius: 25px;
  text-align: center;
  line-height: 20px;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  box-shadow: rgba(0, 0, 0, 0.8) 0px 0px 5px;
  cursor: pointer;
}
#image-upload-component.image-upload .image-upload-content > label {
  padding: 10px;
  width: 100%;
  background-color: #ccc;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  display: block;
  margin-top: 10px;
  cursor: pointer;
  line-height: 50%;
  border-radius: 5px;
}
#image-upload-component.image-upload .image-upload-content > label:hover {
  background-color: #bbb;
}
#image-upload-component.image-upload .image-upload-content > label svg {
  font-size: 30px;
}
#image-upload-component.image-upload .image-upload-content input[type=file] {
  display: none;
}
#image-upload-component.image-upload .image-upload-content .image-upload-box {
  width: 100%;
  border: solid 1px #ccc;
  border-radius: 5px;
  overflow: hidden;
}
#image-upload-component.image-upload .image-upload-content .image-upload-box .slot {
  width: 23%;
  margin: 1%;
  height: 80px;
  color: #ccc;
  border: solid 2px #ccc;
  display: block;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}
#image-upload-component.image-upload .image-upload-content .image-upload-box .slot img {
  min-width: 100%;
  min-height: 100%;
}
#image-upload-component.image-upload .image-upload-content .image-upload-box .slot .btn-close {
  position: absolute;
  float: right;
  top: 5px;
  right: 5px;
  z-index: 2;
  background-color: #aaa;
  height: 25px;
  width: 25px;
  border-radius: 25px;
  text-align: center;
  line-height: 20px;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  box-shadow: rgba(0, 0, 0, 0.8) 0px 0px 5px;
  cursor: pointer;
}
#image-upload-component.image-upload .image-upload-content .image-upload-box label.add-image {
  width: 23%;
  margin: 1%;
  height: 80px;
  color: #ccc;
  border: solid 2px #ccc;
  display: block;
  cursor: pointer;
  border-radius: 5px;
  line-height: 80px;
  text-align: center;
  font-size: 30px;
}
#image-upload-component.image-upload .image-upload-content .image-upload-box label.add-image,
#image-upload-component.image-upload .image-upload-content .image-upload-box .slot {
  float: left;
  top: 0;
}
#image-upload-component.image-upload .image-upload-error {
  margin: 20px 0;
  color: red;
  font-weight: bold;
}
