#file-upload-component.file-upload {
  width: 100%;
}
#file-upload-component.file-upload .file-upload-title {
  margin: 20px 0;
  position: relative;
}
#file-upload-component.file-upload .file-upload-error {
  margin: 20px 0;
  color: red;
  font-weight: bold;
}
#file-upload-component.file-upload .file-upload-mask {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  float: left;
  z-index: 1000;
  font-size: 30px;
}
#file-upload-component.file-upload .file-upload-mask img {
  max-width: 90%;
  max-height: 90%;
}
#file-upload-component.file-upload .file-upload-mask .btn-close-mask {
  position: absolute;
  float: right;
  top: 15px;
  right: 15px;
  z-index: 2;
  background-color: #aaa;
  height: 25px;
  width: 25px;
  border-radius: 25px;
  text-align: center;
  line-height: 20px;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  box-shadow: rgba(0, 0, 0, 0.8) 0px 0px 5px;
  cursor: pointer;
}
#file-upload-component.file-upload .file-upload-content > label {
  padding: 10px;
  width: 100%;
  background-color: #ccc;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  display: block;
  margin-top: 10px;
  cursor: pointer;
  line-height: 50%;
  border-radius: 5px;
}
#file-upload-component.file-upload .file-upload-content > label:hover {
  background-color: #bbb;
}
#file-upload-component.file-upload .file-upload-content > label svg {
  font-size: 30px;
}
#file-upload-component.file-upload .file-upload-content input[type=file] {
  display: none;
}
#file-upload-component.file-upload .file-upload-content .file-upload-box {
  width: 100%;
  border: solid 1px #ccc;
  border-radius: 5px;
  overflow: hidden;
}
#file-upload-component.file-upload .file-upload-content .file-upload-box .slot {
  width: 100%;
  height: 50px;
  border-bottom: solid 2px #ccc;
  display: flex;
  overflow: hidden;
  position: relative;
  float: left;
  top: 0;
}
#file-upload-component.file-upload .file-upload-content .file-upload-box .slot > div.icon {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#file-upload-component.file-upload .file-upload-content .file-upload-box .slot > div.icon > svg {
  font-size: 30px;
}
#file-upload-component.file-upload .file-upload-content .file-upload-box .slot > div.name {
  width: calc(100% - 100px);
  height: 50px;
  line-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
}
#file-upload-component.file-upload .file-upload-content .file-upload-box .slot .btn-close {
  position: absolute;
  float: right;
  top: 10px;
  right: 10px;
  z-index: 2;
  background-color: #eee;
  height: 25px;
  width: 25px;
  border-radius: 25px;
  text-align: center;
  line-height: 20px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  border: solid 1px #aaa;
}
#file-upload-component.file-upload .file-upload-content .file-upload-box .slot-add {
  width: 100%;
  border-bottom: solid 2px #ccc;
  display: flex;
  overflow: hidden;
  position: relative;
  float: left;
  top: 0;
}
#file-upload-component.file-upload .file-upload-content .file-upload-box .slot-add > label.add-file {
  width: 100px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #777;
  border: solid 1px #aaa;
  display: block;
  cursor: pointer;
  border-radius: 15px;
  margin: 10px auto;
}
#file-upload-component.file-upload .file-upload-content .file-upload-box .slot-add > label.add-file:hover {
  background-color: #eee;
}
#file-upload-component.file-upload .file-upload-error {
  margin: 20px 0;
  color: red;
  font-weight: bold;
}
