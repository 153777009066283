@charset "UTF-8";
#checkbox-component.checkbox, #checkbox-component.checkbox-check, #checkbox-component.checkbox-pill, #checkbox-component.checkbox-pill-check, #checkbox-component.checkbox-full, #checkbox-component.check, #checkbox-component.pill, #checkbox-component.pill-check, #checkbox-component.full {
  width: 100%;
}
#checkbox-component.checkbox .checkbox-error, #checkbox-component.checkbox-check .checkbox-error, #checkbox-component.checkbox-pill .checkbox-error, #checkbox-component.checkbox-pill-check .checkbox-error, #checkbox-component.checkbox-full .checkbox-error, #checkbox-component.check .checkbox-error, #checkbox-component.pill .checkbox-error, #checkbox-component.pill-check .checkbox-error, #checkbox-component.full .checkbox-error {
  margin: 20px 0;
  color: red;
  font-weight: bold;
}
#checkbox-component.checkbox .checkbox-title, #checkbox-component.checkbox-check .checkbox-title, #checkbox-component.checkbox-pill .checkbox-title, #checkbox-component.checkbox-pill-check .checkbox-title, #checkbox-component.checkbox-full .checkbox-title, #checkbox-component.check .checkbox-title, #checkbox-component.pill .checkbox-title, #checkbox-component.pill-check .checkbox-title, #checkbox-component.full .checkbox-title {
  margin: 20px 0;
  position: relative;
}
#checkbox-component.checkbox .checkbox-title > span.require, #checkbox-component.checkbox-check .checkbox-title > span.require, #checkbox-component.checkbox-pill .checkbox-title > span.require, #checkbox-component.checkbox-pill-check .checkbox-title > span.require, #checkbox-component.checkbox-full .checkbox-title > span.require, #checkbox-component.check .checkbox-title > span.require, #checkbox-component.pill .checkbox-title > span.require, #checkbox-component.pill-check .checkbox-title > span.require, #checkbox-component.full .checkbox-title > span.require {
  position: absolute;
  float: left;
  left: -5px;
  top: -5px;
  color: red;
}
#checkbox-component.checkbox .checkbox-option, #checkbox-component.checkbox-check .checkbox-option, #checkbox-component.checkbox-pill .checkbox-option, #checkbox-component.checkbox-pill-check .checkbox-option, #checkbox-component.checkbox-full .checkbox-option, #checkbox-component.check .checkbox-option, #checkbox-component.pill .checkbox-option, #checkbox-component.pill-check .checkbox-option, #checkbox-component.full .checkbox-option {
  display: block;
  cursor: pointer;
  margin-bottom: 15px;
  transition: all 0.1s ease-in-out;
}
#checkbox-component.checkbox .checkbox-option .box-properties, #checkbox-component.checkbox-check .checkbox-option .box-properties, #checkbox-component.checkbox-pill .checkbox-option .box-properties, #checkbox-component.checkbox-pill-check .checkbox-option .box-properties, #checkbox-component.checkbox-full .checkbox-option .box-properties, #checkbox-component.check .checkbox-option .box-properties, #checkbox-component.pill .checkbox-option .box-properties, #checkbox-component.pill-check .checkbox-option .box-properties, #checkbox-component.full .checkbox-option .box-properties {
  margin: 10px 0;
  width: 100%;
  padding-left: 30px;
}
#checkbox-component.checkbox .checkbox-option .box-properties input, #checkbox-component.checkbox-check .checkbox-option .box-properties input, #checkbox-component.checkbox-pill .checkbox-option .box-properties input, #checkbox-component.checkbox-pill-check .checkbox-option .box-properties input, #checkbox-component.checkbox-full .checkbox-option .box-properties input, #checkbox-component.check .checkbox-option .box-properties input, #checkbox-component.pill .checkbox-option .box-properties input, #checkbox-component.pill-check .checkbox-option .box-properties input, #checkbox-component.full .checkbox-option .box-properties input {
  width: 100%;
  display: block;
  margin: 10px 0;
  border-radius: 5px;
  padding: 10px;
  outline: none;
  border: solid 1px #ccc;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #f5f5f5;
}
#checkbox-component.checkbox .checkbox-option .box-properties input:focus, #checkbox-component.checkbox-check .checkbox-option .box-properties input:focus, #checkbox-component.checkbox-pill .checkbox-option .box-properties input:focus, #checkbox-component.checkbox-pill-check .checkbox-option .box-properties input:focus, #checkbox-component.checkbox-full .checkbox-option .box-properties input:focus, #checkbox-component.check .checkbox-option .box-properties input:focus, #checkbox-component.pill .checkbox-option .box-properties input:focus, #checkbox-component.pill-check .checkbox-option .box-properties input:focus, #checkbox-component.full .checkbox-option .box-properties input:focus {
  border: solid 1px #aaa;
  box-shadow: 0 0 0 0.2rem #eee;
}
#checkbox-component.checkbox .checkbox-option .box-properties > .other-error, #checkbox-component.checkbox-check .checkbox-option .box-properties > .other-error, #checkbox-component.checkbox-pill .checkbox-option .box-properties > .other-error, #checkbox-component.checkbox-pill-check .checkbox-option .box-properties > .other-error, #checkbox-component.checkbox-full .checkbox-option .box-properties > .other-error, #checkbox-component.check .checkbox-option .box-properties > .other-error, #checkbox-component.pill .checkbox-option .box-properties > .other-error, #checkbox-component.pill-check .checkbox-option .box-properties > .other-error, #checkbox-component.full .checkbox-option .box-properties > .other-error {
  color: red;
}
#checkbox-component.checkbox .checkbox-option input.checkbox-item, #checkbox-component.checkbox-check .checkbox-option input.checkbox-item, #checkbox-component.checkbox-pill .checkbox-option input.checkbox-item, #checkbox-component.checkbox-pill-check .checkbox-option input.checkbox-item, #checkbox-component.checkbox-full .checkbox-option input.checkbox-item, #checkbox-component.check .checkbox-option input.checkbox-item, #checkbox-component.pill .checkbox-option input.checkbox-item, #checkbox-component.pill-check .checkbox-option input.checkbox-item, #checkbox-component.full .checkbox-option input.checkbox-item {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
#checkbox-component.checkbox .checkbox-option input.checkbox-item:checked + label::after, #checkbox-component.checkbox-check .checkbox-option input.checkbox-item:checked + label::after, #checkbox-component.checkbox-pill .checkbox-option input.checkbox-item:checked + label::after, #checkbox-component.checkbox-pill-check .checkbox-option input.checkbox-item:checked + label::after, #checkbox-component.checkbox-full .checkbox-option input.checkbox-item:checked + label::after, #checkbox-component.check .checkbox-option input.checkbox-item:checked + label::after, #checkbox-component.pill .checkbox-option input.checkbox-item:checked + label::after, #checkbox-component.pill-check .checkbox-option input.checkbox-item:checked + label::after, #checkbox-component.full .checkbox-option input.checkbox-item:checked + label::after {
  opacity: 1;
}
#checkbox-component.checkbox .checkbox-option + .box-properties, #checkbox-component.checkbox-check .checkbox-option + .box-properties, #checkbox-component.checkbox-pill .checkbox-option + .box-properties, #checkbox-component.checkbox-pill-check .checkbox-option + .box-properties, #checkbox-component.checkbox-full .checkbox-option + .box-properties, #checkbox-component.check .checkbox-option + .box-properties, #checkbox-component.pill .checkbox-option + .box-properties, #checkbox-component.pill-check .checkbox-option + .box-properties, #checkbox-component.full .checkbox-option + .box-properties {
  margin: 10px 0;
  width: 100%;
  padding-left: 40px;
}
#checkbox-component.checkbox .checkbox-option + .box-properties input, #checkbox-component.checkbox-check .checkbox-option + .box-properties input, #checkbox-component.checkbox-pill .checkbox-option + .box-properties input, #checkbox-component.checkbox-pill-check .checkbox-option + .box-properties input, #checkbox-component.checkbox-full .checkbox-option + .box-properties input, #checkbox-component.check .checkbox-option + .box-properties input, #checkbox-component.pill .checkbox-option + .box-properties input, #checkbox-component.pill-check .checkbox-option + .box-properties input, #checkbox-component.full .checkbox-option + .box-properties input {
  width: 100%;
  display: block;
  margin: 10px 0;
  border-radius: 5px;
  padding: 10px;
  outline: none;
  border: solid 1px #ccc;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #f5f5f5;
}
#checkbox-component.checkbox .checkbox-option + .box-properties input:focus, #checkbox-component.checkbox-check .checkbox-option + .box-properties input:focus, #checkbox-component.checkbox-pill .checkbox-option + .box-properties input:focus, #checkbox-component.checkbox-pill-check .checkbox-option + .box-properties input:focus, #checkbox-component.checkbox-full .checkbox-option + .box-properties input:focus, #checkbox-component.check .checkbox-option + .box-properties input:focus, #checkbox-component.pill .checkbox-option + .box-properties input:focus, #checkbox-component.pill-check .checkbox-option + .box-properties input:focus, #checkbox-component.full .checkbox-option + .box-properties input:focus {
  border: solid 1px #aaa;
  box-shadow: 0 0 0 0.2rem #eee;
}
#checkbox-component.checkbox .checkbox-option label {
  position: relative;
  padding-left: 2.5em;
  display: inline-block;
  cursor: pointer;
  width: 100%;
}
#checkbox-component.checkbox .checkbox-option label::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 1.8em;
  width: 1.8em;
  background: #fff;
  margin-right: 0.5em;
  border: 2px solid #000;
  position: absolute;
  top: 10px;
  left: 0;
  transform: translate(0, -50%);
  border-radius: 5px;
}
#checkbox-component.checkbox .checkbox-option label::after {
  content: "";
  position: absolute;
  left: 4px;
  top: 3px;
  height: 1.1em;
  width: 1.2em;
  opacity: 0;
  background-color: #000;
  border-radius: 3px;
}
#checkbox-component.checkbox-check .checkbox-option label, #checkbox-component.check .checkbox-option label {
  position: relative;
  padding-left: 2.5em;
  display: inline-block;
  cursor: pointer;
  width: 100%;
}
#checkbox-component.checkbox-check .checkbox-option label::before, #checkbox-component.check .checkbox-option label::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 1.8em;
  width: 1.8em;
  background: #fff;
  margin-right: 0.5em;
  border: 2px solid #000;
  position: absolute;
  top: 10px;
  left: 0;
  transform: translate(0, -50%);
  border-radius: 5px;
}
#checkbox-component.checkbox-check .checkbox-option label::after, #checkbox-component.check .checkbox-option label::after {
  content: "✔";
  position: absolute;
  top: 50%;
  left: 0.55em;
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  color: #000;
  font-weight: bold;
  opacity: 0;
  transition: all 0.2s ease;
}
#checkbox-component.checkbox-pill .checkbox-option label, #checkbox-component.pill .checkbox-option label {
  position: relative;
  padding-left: 3.9em;
  display: inline-block;
  cursor: pointer;
  width: 100%;
}
#checkbox-component.checkbox-pill .checkbox-option label::before, #checkbox-component.pill .checkbox-option label::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 1.8em;
  width: 3.2em;
  background: #fff;
  margin-right: 0.5em;
  border: 2px solid #000;
  position: absolute;
  top: 10px;
  left: 0;
  transform: translate(0, -50%);
  border-radius: 0.9em;
}
#checkbox-component.checkbox-pill .checkbox-option label::after, #checkbox-component.pill .checkbox-option label::after {
  content: "";
  position: absolute;
  height: 1em;
  width: 2.4em;
  left: 5px;
  top: 3px;
  opacity: 0;
  background-color: #000;
  border-radius: 0.7em;
}
#checkbox-component.checkbox-pill-check .checkbox-option label, #checkbox-component.pill-check .checkbox-option label {
  position: relative;
  padding-left: 3.9em;
  display: inline-block;
  cursor: pointer;
  width: 100%;
}
#checkbox-component.checkbox-pill-check .checkbox-option label::before, #checkbox-component.pill-check .checkbox-option label::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 1.8em;
  width: 3.2em;
  background: #fff;
  margin-right: 0.5em;
  border: 2px solid #000;
  position: absolute;
  top: 10px;
  left: 0;
  transform: translate(0, -50%);
  border-radius: 0.9em;
}
#checkbox-component.checkbox-pill-check .checkbox-option label::after, #checkbox-component.pill-check .checkbox-option label::after {
  content: "✔";
  position: absolute;
  top: 50%;
  left: 1.1em;
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  color: #000;
  font-weight: bold;
  opacity: 0;
  transition: all 0.2s ease;
}
#checkbox-component.checkbox-full .checkbox-option, #checkbox-component.full .checkbox-option {
  background-color: #eee;
  border: solid 1px #ddd;
  height: 40px;
  margin-bottom: 10px;
  text-align: center;
  line-height: 40px;
  border-radius: 20px;
  cursor: pointer;
}
#checkbox-component.checkbox-full .checkbox-option:hover, #checkbox-component.full .checkbox-option:hover {
  background-color: #e1e1e1;
}
#checkbox-component.checkbox-full .checkbox-option.selected, #checkbox-component.full .checkbox-option.selected {
  background-color: #7b7b7b;
  border: solid 1px #535353;
  color: #fff;
}
#checkbox-component.shadow .checkbox-option {
  box-shadow: 2px 2px 0 0 #414141;
}
#checkbox-component.shadow .checkbox-option:hover {
  transform: translateY(-1px) translateX(-2px);
  box-shadow: 3px 3px 0 0 #414141;
}
#checkbox-component.shadow .checkbox-option.selected {
  transform: translateY(1px) translateX(1px);
  box-shadow: 0 0 0 0 #414141;
}
