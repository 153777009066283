#optin-component .optin-content {
  display: block;
  cursor: pointer;
  margin-bottom: 15px;
  position: relative;
}
#optin-component .optin-content input.optin-item {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
#optin-component .optin-content input.optin-item:checked + label::after {
  opacity: 1;
}
#optin-component .optin-content label {
  position: relative;
  padding-left: 2.5em;
  display: inline-block;
  cursor: pointer;
  width: 100%;
}
#optin-component .optin-content label::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 1.8em;
  width: 1.8em;
  background: #fff;
  margin-right: 0.5em;
  border: 2px solid #000;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  border-radius: 5px;
}
#optin-component .optin-content label::after {
  content: "";
  position: absolute;
  left: 4px;
  top: calc(50% - 8px);
  height: 1.1em;
  width: 1.2em;
  opacity: 0;
  background-color: #000;
  border-radius: 3px;
}
#optin-component .optin-error {
  margin: 20px 0;
  color: red;
  font-weight: bold;
}
