#ces-component {
  /*CES Design*/
}
#ces-component.ces, #ces-component.ces-effort {
  width: 100%;
}
#ces-component.ces .ces-title, #ces-component.ces-effort .ces-title {
  margin: 20px 0;
  position: relative;
}
#ces-component.ces .ces-title > span.require, #ces-component.ces-effort .ces-title > span.require {
  position: absolute;
  float: left;
  left: -5px;
  top: -5px;
  color: red;
}
#ces-component.ces .ces-error, #ces-component.ces-effort .ces-error {
  margin: 20px 0;
  color: red;
  font-weight: bold;
}
#ces-component.ces .ces-item {
  cursor: pointer;
  display: block;
  width: calc(100% - 4px);
  margin: 2px;
  height: 40px;
  background-color: #fff;
  border-radius: 5px;
  text-align: center;
  line-height: 35px;
  color: #222;
  font-weight: bold;
  font-size: 14px;
  border: solid 2px #fff;
}
#ces-component.ces .ces-item.option-color-0 {
  background-color: #dcdcdc;
}
#ces-component.ces .ces-item.option-color-0:hover {
  background-color: #bbbbbb;
  color: #fff;
}
#ces-component.ces .ces-item.option-color-0.selected {
  background-color: #808080;
  border: solid 2px #5f5f5f;
  color: #fff;
}
#ces-component.ces .ces-item.option-color-1 {
  background-color: #ffc8c9;
}
#ces-component.ces .ces-item.option-color-1:hover {
  background-color: #ff0004;
  color: #fff;
}
#ces-component.ces .ces-item.option-color-1.selected {
  background-color: #ff0004;
  border: solid 2px #8e0002;
  color: #fff;
}
#ces-component.ces .ces-item.option-color-2 {
  background-color: #ffe1bd;
}
#ces-component.ces .ces-item.option-color-2:hover {
  background-color: #ff8c00;
  color: #fff;
}
#ces-component.ces .ces-item.option-color-2.selected {
  background-color: #ff8c00;
  border: solid 2px #aa5e00;
  color: #fff;
}
#ces-component.ces .ces-item.option-color-3 {
  background-color: #fff0c1;
}
#ces-component.ces .ces-item.option-color-3:hover {
  background-color: #ffc004;
  color: #fff;
}
#ces-component.ces .ces-item.option-color-3.selected {
  background-color: #ffc004;
  border: solid 2px #9b7400;
  color: #fff;
}
#ces-component.ces .ces-item.option-color-4 {
  background-color: #fbffc0;
}
#ces-component.ces .ces-item.option-color-4:hover {
  background-color: #bdcb00;
  color: #fff;
}
#ces-component.ces .ces-item.option-color-4.selected {
  background-color: #bdcb00;
  border: solid 2px #808900;
  color: #fff;
}
#ces-component.ces .ces-item.option-color-5 {
  background-color: #d4ffbe;
}
#ces-component.ces .ces-item.option-color-5:hover {
  background-color: #38ac00;
  color: #fff;
}
#ces-component.ces .ces-item.option-color-5.selected {
  background-color: #38ac00;
  border: solid 2px #1e5b00;
  color: #fff;
}
#ces-component.ces-effort .ces-effort-box {
  position: relative;
  overflow: hidden;
}
#ces-component.ces-effort .ces-effort-box .ces-effort-text {
  width: 100%;
  padding: 2px;
  position: relative;
  overflow: hidden;
  font-weight: bold;
  color: #666;
}
#ces-component.ces-effort .ces-effort-box .ces-effort-text .right {
  float: right;
}
#ces-component.ces-effort .ces-effort-box .ces-item {
  cursor: pointer;
  float: left;
  width: 14.2828282828%;
  height: 70px;
  background-color: #fff;
  text-align: center;
  line-height: 35px;
  color: #222;
  font-weight: bold;
  font-size: 14px;
}
#ces-component.ces-effort .ces-effort-box .ces-item .number {
  height: 35px;
}
#ces-component.ces-effort .ces-effort-box .ces-item svg {
  font-size: 24px;
}
#ces-component.ces-effort .ces-effort-box .ces-item.ces-color-7 {
  background-color: #ff0d27;
  color: #fff;
}
#ces-component.ces-effort .ces-effort-box .ces-item.ces-color-7.selected {
  box-shadow: inset #c30b1d 0px 0px 0px 5px;
}
#ces-component.ces-effort .ces-effort-box .ces-item.ces-color-6 {
  background-color: #ff8c00;
  color: #fff;
}
#ces-component.ces-effort .ces-effort-box .ces-item.ces-color-6.selected {
  box-shadow: inset #b16100 0px 0px 0px 5px;
}
#ces-component.ces-effort .ces-effort-box .ces-item.ces-color-5 {
  background-color: #ffc004;
}
#ces-component.ces-effort .ces-effort-box .ces-item.ces-color-5.selected {
  box-shadow: inset #ad8201 0px 0px 0px 5px;
}
#ces-component.ces-effort .ces-effort-box .ces-item.ces-color-4 {
  background-color: #ffff00;
}
#ces-component.ces-effort .ces-effort-box .ces-item.ces-color-4.selected {
  box-shadow: inset #a2a200 0px 0px 0px 5px;
}
#ces-component.ces-effort .ces-effort-box .ces-item.ces-color-3 {
  background-color: #e7e63a;
}
#ces-component.ces-effort .ces-effort-box .ces-item.ces-color-3.selected {
  box-shadow: inset #7f7f17 0px 0px 0px 5px;
}
#ces-component.ces-effort .ces-effort-box .ces-item.ces-color-2 {
  background-color: #72e73a;
  color: #fff;
}
#ces-component.ces-effort .ces-effort-box .ces-item.ces-color-2.selected {
  box-shadow: inset #41b50b 0px 0px 0px 5px;
}
#ces-component.ces-effort .ces-effort-box .ces-item.ces-color-1 {
  background-color: #329500;
  color: #fff;
}
#ces-component.ces-effort .ces-effort-box .ces-item.ces-color-1.selected {
  box-shadow: inset #297b00 0px 0px 0px 5px;
}
