:root {
  --color-inovyo: #9a2376;
  --color-inovyo-light: #b44292;
  --color-inovyo-dark: #7b0e5a;
  --color-inovyo-green: #009045;
  --color-inovyo-yellow: #ffc400;
  --color-inovyo-yellow-light: #ffd23e;
  --color-inovyo-yellow-dark: #d1a100;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

::selection {
  background-color: #3f3f3f;
  color: #ffffff;
}

body {
  font-family: "Nunito Sans", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-text-size-adjust: 100%;
  color: #444;
  overflow-y: auto;
}

/*** MANIPULANDO O ANTD ***/
.ant-layout {
  background: #fff;
}
