#nps-component {
  /*NPS colorfull*/
  /*NPS triade*/
}
#nps-component.nps {
  width: 100%;
}
#nps-component.nps .nps-title {
  margin: 20px 0;
  position: relative;
}
#nps-component.nps .nps-title > span.require {
  position: absolute;
  float: left;
  left: -5px;
  top: -5px;
  color: #ff0000;
}
#nps-component.nps .nps-error {
  margin: 20px 0;
  color: red;
  font-weight: bold;
}
#nps-component.nps .nps-content {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}
#nps-component.nps .nps-content .item {
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  display: flex;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 5px;
  color: #222;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  border: solid 1px #949494;
}
#nps-component.nps .nps-content .item:hover {
  background-color: #ddd;
  color: #222;
}
#nps-component.nps .nps-content .item.selected {
  background-color: #222;
  color: white;
}
#nps-component.rounded .nps-content .item {
  border-radius: 50%;
}
#nps-component.shadow .nps-content .item {
  box-shadow: 2px 2px 0 0 #414141;
}
#nps-component.shadow .nps-content .item:hover {
  transform: translateY(-1px) translateX(-2px);
  box-shadow: 3px 3px 0 0 #414141;
}
#nps-component.shadow .nps-content .item.selected {
  transform: translateY(1px) translateX(1px);
  box-shadow: 0 0 0 0 #414141;
}
#nps-component.colorfull .nps-content .item {
  border-style: solid;
  border-width: 1px;
}
#nps-component.colorfull .nps-content .item.option-color-0 {
  background-color: #ffc8c9;
}
#nps-component.colorfull .nps-content .item.option-color-0:hover {
  background-color: #ff0004;
  color: #fff;
}
#nps-component.colorfull .nps-content .item.option-color-0.selected {
  background-color: #ff0004;
  color: #fff;
  border-color: #6d0002;
}
#nps-component.colorfull .nps-content .item.option-color-1 {
  background-color: #ffc8c8;
}
#nps-component.colorfull .nps-content .item.option-color-1:hover {
  background-color: #ff2024;
  color: #fff;
}
#nps-component.colorfull .nps-content .item.option-color-1.selected {
  background-color: #ff2024;
  color: #fff;
  border-color: #961416;
}
#nps-component.colorfull .nps-content .item.option-color-2 {
  background-color: #ffd5c9;
}
#nps-component.colorfull .nps-content .item.option-color-2:hover {
  background-color: #ff3700;
  color: #fff;
}
#nps-component.colorfull .nps-content .item.option-color-2.selected {
  background-color: #ff3700;
  color: #fff;
  border-color: #851d00;
}
#nps-component.colorfull .nps-content .item.option-color-3 {
  background-color: #ffddc8;
}
#nps-component.colorfull .nps-content .item.option-color-3:hover {
  background-color: #ff6200;
  color: #fff;
}
#nps-component.colorfull .nps-content .item.option-color-3.selected {
  background-color: #ff6200;
  color: #fff;
  border-color: #853300;
}
#nps-component.colorfull .nps-content .item.option-color-4 {
  background-color: #ffeacc;
}
#nps-component.colorfull .nps-content .item.option-color-4:hover {
  background-color: #ffa628;
  color: #fff;
}
#nps-component.colorfull .nps-content .item.option-color-4.selected {
  background-color: #ffa628;
  color: #fff;
  border-color: #7c5113;
}
#nps-component.colorfull .nps-content .item.option-color-5 {
  background-color: #fff2cc;
}
#nps-component.colorfull .nps-content .item.option-color-5:hover {
  background-color: #ffc004;
  color: #fff;
}
#nps-component.colorfull .nps-content .item.option-color-5.selected {
  background-color: #ffc004;
  color: #fff;
  border-color: #8a6700;
}
#nps-component.colorfull .nps-content .item.option-color-6 {
  background-color: #fff7c9;
}
#nps-component.colorfull .nps-content .item.option-color-6:hover {
  background-color: #e2c309;
  color: #fff;
}
#nps-component.colorfull .nps-content .item.option-color-6.selected {
  background-color: #e2c309;
  color: #fff;
  border-color: #665705;
}
#nps-component.colorfull .nps-content .item.option-color-7 {
  background-color: #fcffcb;
}
#nps-component.colorfull .nps-content .item.option-color-7:hover {
  background-color: #bdcb00;
  color: #fff;
}
#nps-component.colorfull .nps-content .item.option-color-7.selected {
  background-color: #bdcb00;
  color: #fff;
  border-color: #575e00;
}
#nps-component.colorfull .nps-content .item.option-color-8 {
  background-color: #f3ffc7;
}
#nps-component.colorfull .nps-content .item.option-color-8:hover {
  background-color: #9eca01;
  color: #fff;
}
#nps-component.colorfull .nps-content .item.option-color-8.selected {
  background-color: #9eca01;
  color: #fff;
  border-color: #546b00;
}
#nps-component.colorfull .nps-content .item.option-color-9 {
  background-color: #e9ffc7;
}
#nps-component.colorfull .nps-content .item.option-color-9:hover {
  background-color: #7ec706;
  color: #fff;
}
#nps-component.colorfull .nps-content .item.option-color-9.selected {
  background-color: #7ec706;
  color: #fff;
  border-color: #345203;
}
#nps-component.colorfull .nps-content .item.option-color-10 {
  background-color: #d9ffc5;
}
#nps-component.colorfull .nps-content .item.option-color-10:hover {
  background-color: #38ac00;
  color: #fff;
}
#nps-component.colorfull .nps-content .item.option-color-10.selected {
  background-color: #38ac00;
  color: #fff;
  border-color: #206100;
}
#nps-component.triade .nps-content .item {
  border-style: solid;
  border-width: 1px;
}
#nps-component.triade .nps-content .item.option-color-0, #nps-component.triade .nps-content .item.option-color-1, #nps-component.triade .nps-content .item.option-color-2, #nps-component.triade .nps-content .item.option-color-3, #nps-component.triade .nps-content .item.option-color-4, #nps-component.triade .nps-content .item.option-color-5, #nps-component.triade .nps-content .item.option-color-6 {
  background-color: #ffc8c9;
}
#nps-component.triade .nps-content .item.option-color-0:hover, #nps-component.triade .nps-content .item.option-color-1:hover, #nps-component.triade .nps-content .item.option-color-2:hover, #nps-component.triade .nps-content .item.option-color-3:hover, #nps-component.triade .nps-content .item.option-color-4:hover, #nps-component.triade .nps-content .item.option-color-5:hover, #nps-component.triade .nps-content .item.option-color-6:hover {
  background-color: #ff0004;
  color: #fff;
}
#nps-component.triade .nps-content .item.option-color-0.selected, #nps-component.triade .nps-content .item.option-color-1.selected, #nps-component.triade .nps-content .item.option-color-2.selected, #nps-component.triade .nps-content .item.option-color-3.selected, #nps-component.triade .nps-content .item.option-color-4.selected, #nps-component.triade .nps-content .item.option-color-5.selected, #nps-component.triade .nps-content .item.option-color-6.selected {
  background-color: #ff0004;
  color: #fff;
  border-color: #6d0002;
}
#nps-component.triade .nps-content .item.option-color-7, #nps-component.triade .nps-content .item.option-color-8 {
  background-color: #fff2cc;
}
#nps-component.triade .nps-content .item.option-color-7:hover, #nps-component.triade .nps-content .item.option-color-8:hover {
  background-color: #ffc004;
  color: #fff;
}
#nps-component.triade .nps-content .item.option-color-7.selected, #nps-component.triade .nps-content .item.option-color-8.selected {
  background-color: #ffc004;
  color: #fff;
  border-color: #8a6700;
}
#nps-component.triade .nps-content .item.option-color-9, #nps-component.triade .nps-content .item.option-color-10 {
  background-color: #d9ffc5;
}
#nps-component.triade .nps-content .item.option-color-9:hover, #nps-component.triade .nps-content .item.option-color-10:hover {
  background-color: #38ac00;
  color: #fff;
}
#nps-component.triade .nps-content .item.option-color-9.selected, #nps-component.triade .nps-content .item.option-color-10.selected {
  background-color: #38ac00;
  color: #fff;
  border-color: #206100;
}
#nps-component.standard .nps-content-standard {
  display: flex;
  flex-direction: column;
}
#nps-component.standard .nps-content-standard .numbers,
#nps-component.standard .nps-content-standard .radio {
  display: flex;
  flex-direction: row;
}
#nps-component.standard .nps-content-standard .numbers span,
#nps-component.standard .nps-content-standard .radio span {
  display: flex;
  width: 40px;
  height: 20px;
  justify-content: center;
  align-items: center;
  flex: 1;
}
#nps-component.standard .nps-content-standard .radio span {
  height: 40px;
}
#nps-component.standard .nps-content-standard .radio span input[type=radio] {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
#nps-component.standard .nps-content-standard .radio span input[type=radio] + label {
  position: relative;
  cursor: pointer;
  padding-left: 20px;
}
#nps-component.standard .nps-content-standard .radio span input[type=radio] + label::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  top: -4px;
  left: 0;
  bottom: 0;
  border: solid 2px black;
  vertical-align: bottom;
  border-radius: 50%;
}
#nps-component.standard .nps-content-standard .radio span input[type=radio]:checked + label::after {
  content: "";
  position: absolute;
  left: 5px;
  bottom: 4px;
  width: 10px;
  height: 10px;
  top: 1px;
  background-color: black;
  border-radius: 50%;
}
#nps-component.nps-colorfull .item {
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
}
#nps-component.nps-colorfull .item.option-color-0 {
  background-color: #ffc8c9;
}
#nps-component.nps-colorfull .item.option-color-0:hover {
  background-color: #ff0004;
  color: #fff;
}
#nps-component.nps-colorfull .item.option-color-0.selected {
  background-color: #ff0004;
  color: #fff;
  border-color: #6d0002;
}
#nps-component.nps-colorfull .item.option-color-1 {
  background-color: #ffc8c8;
}
#nps-component.nps-colorfull .item.option-color-1:hover {
  background-color: #ff2024;
  color: #fff;
}
#nps-component.nps-colorfull .item.option-color-1.selected {
  background-color: #ff2024;
  color: #fff;
  border-color: #961416;
}
#nps-component.nps-colorfull .item.option-color-2 {
  background-color: #ffd5c9;
}
#nps-component.nps-colorfull .item.option-color-2:hover {
  background-color: #ff3700;
  color: #fff;
}
#nps-component.nps-colorfull .item.option-color-2.selected {
  background-color: #ff3700;
  color: #fff;
  border-color: #851d00;
}
#nps-component.nps-colorfull .item.option-color-3 {
  background-color: #ffddc8;
}
#nps-component.nps-colorfull .item.option-color-3:hover {
  background-color: #ff6200;
  color: #fff;
}
#nps-component.nps-colorfull .item.option-color-3.selected {
  background-color: #ff6200;
  color: #fff;
  border-color: #853300;
}
#nps-component.nps-colorfull .item.option-color-4 {
  background-color: #ffeacc;
}
#nps-component.nps-colorfull .item.option-color-4:hover {
  background-color: #ffa628;
  color: #fff;
}
#nps-component.nps-colorfull .item.option-color-4.selected {
  background-color: #ffa628;
  color: #fff;
  border-color: #7c5113;
}
#nps-component.nps-colorfull .item.option-color-5 {
  background-color: #fff2cc;
}
#nps-component.nps-colorfull .item.option-color-5:hover {
  background-color: #ffc004;
  color: #fff;
}
#nps-component.nps-colorfull .item.option-color-5.selected {
  background-color: #ffc004;
  color: #fff;
  border-color: #8a6700;
}
#nps-component.nps-colorfull .item.option-color-6 {
  background-color: #fff7c9;
}
#nps-component.nps-colorfull .item.option-color-6:hover {
  background-color: #e2c309;
  color: #fff;
}
#nps-component.nps-colorfull .item.option-color-6.selected {
  background-color: #e2c309;
  color: #fff;
  border-color: #665705;
}
#nps-component.nps-colorfull .item.option-color-7 {
  background-color: #fcffcb;
}
#nps-component.nps-colorfull .item.option-color-7:hover {
  background-color: #bdcb00;
  color: #fff;
}
#nps-component.nps-colorfull .item.option-color-7.selected {
  background-color: #bdcb00;
  color: #fff;
  border-color: #575e00;
}
#nps-component.nps-colorfull .item.option-color-8 {
  background-color: #f3ffc7;
}
#nps-component.nps-colorfull .item.option-color-8:hover {
  background-color: #9eca01;
  color: #fff;
}
#nps-component.nps-colorfull .item.option-color-8.selected {
  background-color: #9eca01;
  color: #fff;
  border-color: #546b00;
}
#nps-component.nps-colorfull .item.option-color-9 {
  background-color: #e9ffc7;
}
#nps-component.nps-colorfull .item.option-color-9:hover {
  background-color: #7ec706;
  color: #fff;
}
#nps-component.nps-colorfull .item.option-color-9.selected {
  background-color: #7ec706;
  color: #fff;
  border-color: #345203;
}
#nps-component.nps-colorfull .item.option-color-10 {
  background-color: #d9ffc5;
}
#nps-component.nps-colorfull .item.option-color-10:hover {
  background-color: #38ac00;
  color: #fff;
}
#nps-component.nps-colorfull .item.option-color-10.selected {
  background-color: #38ac00;
  color: #fff;
  border-color: #206100;
}
#nps-component.nps-triade .item {
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
}
#nps-component.nps-triade .item.option-color-0, #nps-component.nps-triade .item.option-color-1, #nps-component.nps-triade .item.option-color-2, #nps-component.nps-triade .item.option-color-3, #nps-component.nps-triade .item.option-color-4, #nps-component.nps-triade .item.option-color-5, #nps-component.nps-triade .item.option-color-6 {
  background-color: #ffc8c9;
}
#nps-component.nps-triade .item.option-color-0:hover, #nps-component.nps-triade .item.option-color-1:hover, #nps-component.nps-triade .item.option-color-2:hover, #nps-component.nps-triade .item.option-color-3:hover, #nps-component.nps-triade .item.option-color-4:hover, #nps-component.nps-triade .item.option-color-5:hover, #nps-component.nps-triade .item.option-color-6:hover {
  background-color: #ff0004;
  color: #fff;
}
#nps-component.nps-triade .item.option-color-0.selected, #nps-component.nps-triade .item.option-color-1.selected, #nps-component.nps-triade .item.option-color-2.selected, #nps-component.nps-triade .item.option-color-3.selected, #nps-component.nps-triade .item.option-color-4.selected, #nps-component.nps-triade .item.option-color-5.selected, #nps-component.nps-triade .item.option-color-6.selected {
  background-color: #ff0004;
  color: #fff;
  border-color: #6d0002;
}
#nps-component.nps-triade .item.option-color-7, #nps-component.nps-triade .item.option-color-8 {
  background-color: #fff2cc;
}
#nps-component.nps-triade .item.option-color-7:hover, #nps-component.nps-triade .item.option-color-8:hover {
  background-color: #ffc004;
  color: #fff;
}
#nps-component.nps-triade .item.option-color-7.selected, #nps-component.nps-triade .item.option-color-8.selected {
  background-color: #ffc004;
  color: #fff;
  border-color: #8a6700;
}
#nps-component.nps-triade .item.option-color-9, #nps-component.nps-triade .item.option-color-10 {
  background-color: #d9ffc5;
}
#nps-component.nps-triade .item.option-color-9:hover, #nps-component.nps-triade .item.option-color-10:hover {
  background-color: #38ac00;
  color: #fff;
}
#nps-component.nps-triade .item.option-color-9.selected, #nps-component.nps-triade .item.option-color-10.selected {
  background-color: #38ac00;
  color: #fff;
  border-color: #206100;
}

@media all and (max-width: 480px) {
  #nps-component.rounded .nps-content .item {
    height: 30px;
    width: 30px;
    font-size: 10px;
  }
}
