#mosaic-component.mosaic {
  width: 100%;
}
#mosaic-component.mosaic .mosaic-title {
  margin: 20px 0;
  position: relative;
}
#mosaic-component.mosaic .mosaic-title > span.require {
  position: absolute;
  float: left;
  left: -5px;
  top: -5px;
  color: red;
}
#mosaic-component.mosaic .mosaic-error {
  margin: 20px 0;
  color: red;
  font-weight: bold;
}
#mosaic-component.mosaic .mosaic-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
#mosaic-component.mosaic .mosaic-content .mosaic-item {
  cursor: pointer;
  display: block;
  width: 23%;
  margin: 1%;
  height: 100px;
  background-color: #fff;
  background-repeat: no-repeat;
  border-radius: 5px;
  text-align: center;
  color: #222;
  font-weight: bold;
  font-size: 12px;
  border: 1px solid #ccc;
}
#mosaic-component.mosaic .mosaic-content .mosaic-item:hover {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 0px 5px;
}
#mosaic-component.mosaic .mosaic-content .mosaic-item.selected {
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 0px 5px;
}

@media all and (max-width: 480px) {
  #mosaic-component.mosaic .mosaic-content .mosaic-item {
    width: 46%;
  }
}
