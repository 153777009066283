#home-survey {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#home-survey .content {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#home-survey .content .logo img {
  width: 300px;
  margin-bottom: 10px;
}
#home-survey .content .message {
  width: 100%;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
}
#home-survey .content .message span {
  color: red;
  font-size: 18px;
}
#home-survey .content .footer {
  margin-top: 50px;
}
